<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <!--begin: Search -->
    <div
      class="kt-header__topbar-item kt-header__topbar-item--search"
      id="kt_quick_search_toggle"
    >
      <div class="kt-header__topbar-wrapper" data-toggle="dropdown">
        <span class="kt-header__topbar-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-lg dropdown-menu-right"
        v-on:click.stop
      >
        <KTSearchDefault></KTSearchDefault>
      </div>
    </div>
    <!--end: Search -->

    <!--begin: Notifications -->
    <div class="kt-header__topbar-item" id="kt_notification_toggle">
      <div class="kt-header__topbar-wrapper" data-toggle="dropdown">
        <span class="kt-header__topbar-icon kt-pulse kt-pulse--brand">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M2.56 10.682l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121zm12 0l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121z" fill="#000" opacity=".3"/><path d="M8.56 16.682l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0l-2.121-2.122a1.5 1.5 0 010-2.121zm0-12l2.122-2.121a1.5 1.5 0 012.121 0l2.122 2.121a1.5 1.5 0 010 2.121l-2.122 2.122a1.5 1.5 0 01-2.121 0L8.561 6.803a1.5 1.5 0 010-2.121z" fill="#000"/></g></svg>
          <span class="kt-pulse__ring"></span>
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </div>
    </div>
    <!--end: Notifications -->

    <!--begin: Language bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--langs">
      <div
        class="kt-header__topbar-wrapper"
        id="kt_language_toggle"
        data-toggle="dropdown"
      >
        <span class="kt-header__topbar-icon">
          <img :src="languageFlag || getLanguageFlag" alt="" />
        </span>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownLanguage
          v-on:language-changed="onLanguageChanged"
        ></KTDropdownLanguage>
      </div>
    </div>
    <!--end: Language bar -->

    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div
        class="kt-header__topbar-wrapper"
        id="kt_user_toggle"
        data-toggle="dropdown"
      >
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome kt-hidden-mobile"
            >{{ $t("GENERAL.HI") }},</span
          >
          <span class="kt-header__topbar-username kt-hidden-mobile">{{
            userName
          }}</span>
          <img v-if="isPhotoLoaded" alt="Pic" :src="userPhoto" />
          <span
            v-if="!isPhotoLoaded"
            class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
            >{{ userNameFirstLetter }}</span
          >
        </div>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownUser></KTDropdownUser>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTSearchDefault from "@/views/theme/topbar/SearchDefault.vue";
import KTDropdownNotification from "@/views/theme/topbar/DropdownNotification.vue";
import KTDropdownLanguage from "@/views/theme/topbar/DropdownLanguage.vue";
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import i18nService from "@/common/i18n.service.js";
import { getUserName, getUserPhoto } from "@/common/jwt.service";
import { mapState } from "vuex";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    KTSearchDefault,
    KTDropdownNotification,
    KTDropdownLanguage,
    KTDropdownUser
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  },
  computed: {
    ...mapState({
      isPhotoLoaded: state => state.auth.isPhotoLoaded
    }),
    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    userName() {
      return getUserName();
    },
    userNameFirstLetter() {
      return getUserName() != null ? getUserName().charAt(0) : null;
    },
    userPhoto() {
      if (this.isPhotoLoaded) {
        return getUserPhoto();
      }
      return null;
    }
  }
};
</script>
